import React, { Component } from 'react';
import { Row } from 'reactstrap';
import Footer from './footer';
import { NavMenu } from './nav_menu';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <>
        <NavMenu />
        <Row className='mb-70 nav-space'>
        {this.props.children}
        </Row>
        <Footer />
      </>
    );
  }
}
