import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
export const appName = 'Scorpion Oilfield Services Limited';
export const appAddress = 'Scorpion’s head office is located in Lagos with our operational base located in Port Harcourt. Ground floor, 20b, Chief Albert Iyorah Street, Lekki phase 1, Lagos, Nigeria';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

serviceWorker.register();
