import React from 'react';
import Banner from '../components/banner';
import otherIdentites from '../data/other_identities.json'
import { CompanyProfile, HomeLayout, Identities } from './home';

export default function About(props) {
    return (
        <div className='w-100'>
            <Banner name='About Us' />
            <HomeLayout className='even-tone myFadeIn'>
                <CompanyProfile title='Company Profile' src='companyProfile.jpeg' />
            </HomeLayout>
            <HomeLayout className='odd-tone'>
                <Identities identities={otherIdentites} />
            </HomeLayout>
        </div>
    )
}