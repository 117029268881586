import React from 'react';
import './image.css';

export default function Image({src}) {
    return (
        <>
        <div style={{backgroundImage: `url("images/${src}")`}} className='image-display no-mobile'></div>
        <img src={`images/${src}`} className='img-fluid no-desktop' alt='background display' />
        </>
    )
}

//'aboutUs.jpg'