import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { appName } from '..';
import contact from '../data/contact.json';
import './contact.css';
import { HomeLayout, TwoSidedLayout } from './home';

import { faHome, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import Banner from '../components/banner';

export default function Contact() {
    return (
        <div className='w-100'>
            <Banner name='Say Hello' />
            <HomeLayout className='even-tone myFadeIn'>
                <TwoSidedLayout
                    title='Contact Us'
                    left={() => <MapLayout />}
                    right={() => <ContactDetail />}
                />
            </HomeLayout>
        </div>
    )
}

const MapLayout = () => {
    return (
        <img src='images/mapInfo.png' alt='compnay profile information' className='img-fluid mb-4' />
    )
}

const ContactDetail = () => {
    return (
        <div>
            <h4>{appName}</h4>
            <FontAwesomeIcon icon={faHome} className='mt-4' />
            <div>
                <span
                    dangerouslySetInnerHTML={{
                        __html: contact.info,
                    }}
                ></span>
                <p><span className='mr-2'><FontAwesomeIcon icon={faEnvelope} /></span> <a className='app-text-color' href='mailto:info@scorpion-oilservices.com'>info@scorpion-oilservices.com</a></p>
                <p><span className='mr-2'><FontAwesomeIcon icon={faPhone} /></span> <a className='app-text-color' href='tel:+234 702 500 5257'>+234 702 500 5257</a></p>
            </div>
        </div>
    )
}