import React from 'react';
import AppCarousel from '../components/app_carousel';
import companyProfile from '../data/company_profile.json';
import products from '../data/products.json';
import identities from '../data/identity.json';
import './home.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import VisibilitySensor from "react-visibility-sensor";
import { Link } from 'react-router-dom';
import Image from '../components/image';

export function Home() {
  return (
    <div className='w-100'>
      <AppCarousel />
      <HomeLayout className='odd-tone'>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <CompanyProfile className={isVisible ? "fadeIn enter" : "fadeIn"} />
          )}
        </VisibilitySensor>

      </HomeLayout>
      <HomeLayout className='even-tone'>
        <VisibilitySensor partialVisibility offset={{ bottom: -400 }}>
          {({ isVisible }) => (
            <Services className={isVisible ? "slideUp enter" : "slideUp"} />
          )}
        </VisibilitySensor>


      </HomeLayout>

      <HomeLayout className='odd-tone'>
        <Identities identities={identities} />
      </HomeLayout>

    </div>
  );
}

Home.displayName = Home.name;

export const HomeLayout = ({ className, children }) => {
  return (
    <div className={`${className} row`}>
      <div className='container mt-4 mb-4'>
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

const Services = ({ className }) => {
  return (
    <TwoSidedLayout
      className={className}
      title='Services'
      left={() => products.map((product, index) => <Service key={product.id} index={index} lastIndex={products.length - 1} name={product.name} />)}
      right={() => <Image src='generalConstruction.jpg' />} />
  )
}

const Service = ({ name, index, lastIndex }) => {
  return (
    <div className={`d-flex j-content border ${index !== lastIndex ? 'mb-3' : 'm-mb-3'} p-2`}>
      <p className='no-mb pr-3'><Link to='/services' className='text-capitalize'>{name}</Link></p>
      <FontAwesomeIcon icon={faAngleRight} />
    </div>
  )
}

export const CompanyProfile = ({ className, title, src }) => {
  return (
    <TwoSidedLayout
      className={className}
      title={title || 'Our Story'}
      left={() => companyProfile.map((profile, index) => <p className={index === companyProfile.length -1 ? 'mb-0 m-mb-3': ''} key={profile.id}>{profile.description}</p>)}
      right={() => <Image src={src || 'aboutUs.jpg'} />} />

  )
}

export const TwoSidedLayout = ({ left, right, title, className }) => {
  return (
    <div className={className || ''}>
      <TopHeader title={title} />
      <div className='d-flex flex-info'>
        <div className='text-justify flex-occupy flex-item'>
          {left()}
        </div>
        <div className='flex-item flex-occupy'>
          {right()}
        </div>

      </div>
    </div>
  )
}

export const TopHeader = ({ title }) => {
  return (
    <>
      <h2>{title}</h2>
      <hr />
    </>
  )
}

export const Identities = ({ identities }) => {
  return (
    <div>
      <div className='d-flex flex-info'>
        {identities.map(identity => <Identity key={identity.id} title={identity.title} description={identity.description} />)}
      </div>
    </div>
  )
}

const Identity = ({ title, description }) => {
  return (
    <div className="card flex-occupy flex-item">
      <div className="card-header text-bold">
        {title}
      </div>
      <div className="card-body">
        <p className="card-text">
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
        </p>
      </div>
    </div>

  )
}