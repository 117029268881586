import React, { useEffect, Fragment } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

export const ScrollToTop = withRouter(({ history, children }) => {
    const { pathname } = useLocation();
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    }, [pathname, history]);

    return <Fragment>{children}</Fragment>;
})