import React, { useEffect, useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './nav_menu.css';
import navItems from '../data/nav_items.json';

export function NavMenu() {
  const [collapsed, setCollapsed] = useState(true);
  const { pathname } = useLocation();

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(true);
  }, [pathname])

  return (
    <header>
      <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow" light>
        <div className='container-fluid'>
          <NavbarBrand tag={Link} to="/"><img src='images/scorpion_logo.svg' className='logo-size' alt='application brand identity logo'/></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse mt-3 mb-3" isOpen={!collapsed} navbar>

            <ul className="navbar-nav flex-grow">
              {navItems.map((item) => {
                return (
                  <NavItem key={item.id} className='m-mr-3'>
                    <NavLink tag={Link} active={pathname === item.url} className="text-dark" to={item.url}>{item.name}</NavLink>
                  </NavItem>
                )
              })}
            </ul>
          </Collapse>
        </div>
      </Navbar>
    </header>
  );
}


NavMenu.displayName = NavMenu.name;