import React from 'react';
import { HomeLayout, TopHeader } from './home';
import './services.css';
import products from '../data/products.json';
import Banner from '../components/banner';

export default function Service() {
    return (
        <div className='w-100'>
            <Banner name='Services' />
            <HomeLayout className='even-tone'>
                <div>
                    <TopHeader title='Our Services' />
                    <Items />
                </div>
            </HomeLayout>
        </div>
    )
}

const Items = () => {
    return (
        <div className="parent-wrapper">
            <div className="parent">
                {products.map(product => <ServiceItem key={product.id} src={product.image} name={product.name} description={product.description} />)}
            </div>
        </div>
    )
}

const ServiceItem = ({ src, name, description }) => {
    return (
        <div className="card child myFadeIn z-hover-effect p-r">
            <img className="card-img-top" src={src} alt={name} />
            <div className="card-body">
                <p className="card-text text-big text-capitalize">{name}</p>
            </div>
            {
                description
                &&
                <div className='grow-description'>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    ></span>
                </div>
            }
        </div>
    )
}