import React from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout';
import { Home } from './pages/home';


import './custom.css'
import About from './pages/about';
import Contact from './pages/contact';
import Service from './pages/services';
import { ScrollToTop } from './components/scroll_to_top';

export default function App() {
  return (
    <ScrollToTop>
      <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/services' component={Service} />
      </Layout>
    </ScrollToTop>
  );
}

App.displayName = App.name;