import React from 'react';
import { appName } from '..';
import './footer.css';
export default function Footer() {
    return (
        <footer>
            <div id="footer">
                <div className="container-fluid">
                    <p className="footer-block text-white text-center pt-4 plr-15">{appName}</p>
                </div>
            </div>
        </footer>
    )
}